import React, { useEffect, useRef, useState } from "react";
import algoliasearch from "algoliasearch/lite";
import "./App.css";

const APPLICATION_KEY = "6XK7SX81UY";
const SEARCH_ONLY_API_KEY = "f5edd88b7d33cfe0fbe99473e509edd4";
const SEARCH_INDEX = "wp_posts_page";

const client = algoliasearch(APPLICATION_KEY, SEARCH_ONLY_API_KEY);
const index = client.initIndex(SEARCH_INDEX);

const App = () => {
  const el = useRef(null);
  const [searchResults, updateSearchResults] = useState([]);
  const [isOpen, showResultsPanel] = useState(false);

  useEffect(() => {
    const handleClick = e => {
      if (el.current && !el.current.contains(e.target)) {
        showResultsPanel(false);
      }
    }
    document.addEventListener("click", handleClick, true);
    return () => {
      document.removeEventListener("click", handleClick, true);
    };
  }, [el, showResultsPanel]);

  const showPanel = isOpen && searchResults && searchResults.length > 0;
  const inputClassName = showPanel ? "condo-search-input open" : "condo-search-input";
  return (
    <div className="condo-search-wrapper" ref={el}>
      <input
        className={inputClassName}
        placeholder="Search all condos"
        onChange={async e => {
          const { hits } = await index.search(e.target.value);
          console.log(hits);
          updateSearchResults(hits);
        }}
        onFocus={() => {
          showResultsPanel(true);
        }}
      />
      {showPanel && (
        <div className="condo-search-results">
          {searchResults.map(result => (
            <a href={result.permalink} className="condo-search-results-list-item">{result.post_title}</a>
          ))}
        </div>
      )}
    </div>
  );
}

export default App;
